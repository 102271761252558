import React from "react"
import Slider from "react-slick"
import Eyebrow from "./Utility/eyebrow"
import H1 from "./Utility/heading1"
import H3 from "./Utility/heading3"
import Button from "./Utility/button"

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default props => (
  <div class={`hero ${props.heroDirection} ${props.heroClass}`}>
    <div class="media">
      <Slider {...settings}>{props.children}</Slider>
    </div>
    <div class="content" style={{ backgroundImage: `url(${props.bgImage})` }}>
      <Eyebrow
        eyebrowText={props.eyebrowText}
        eyebrowColor={props.eyebrowColor}
      />
      <H1
        headingText1={props.headingText1}
        headingColor1={props.headingColor1}
      />
      <H3
        headingText1={props.subheadingText1}
        headingText2={props.subheadingText2}
        headingColor1={props.subheadingColor1}
        headingColor2={props.subheadingColor2}
      />
      <p className="hero-body mb-5 mt-n3" style={{ color: props.bodyColor }}>
        {props.bodyText}
      </p>
      {props.bodyText2 && (
        <p className="" style={{ color: props.bodyColor }}>
          {props.bodyText2}
        </p>
      )}
      {props.buttonText && (
        <Button
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          buttonBGColor={props.buttonBGColor}
        />
      )}
    </div>
    {props.footnoteText && (
      <p class="note" style={{ color: props.footnoteColor }}>
        {props.footnoteText}
      </p>
    )}
  </div>
)
