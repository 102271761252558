import React from "react"
import H3 from "./Utility/heading3"
import Button from "./Utility/button"

export default props => (
    <div id={props.idName} class="content" style={{ backgroundImage: `url(${props.bgImage})`, marginBottom: props.ctaBottomMargin }}>
        {/* <img class="badge" src={props.badgeSrc} /> */}
        <H3 class="content-body" className="content-body-news" headingText2={props.headingText2} headingColor2={props.headingColor2} />
        <p class="content-body-news" style={{ color: props.bodyColor }}>{props.bodyText}</p>
        {'\n'}
        <div className="left">
            {props.children[0]}
            {props.children[1]}
            {props.children[2]}
            {props.children[3]}
            {props.children[4]}
            {props.children[5]}
        </div>
        <div className="right">
            {props.children[6]}
            {props.children[7]}
            {props.children[8]}
            {props.children[9]}
			{props.children[10]}
			{props.children[11]}
        </div>
        {/* {props.children} */}
    </div >
)