import React from "react"
import H3 from "./Utility/heading3"
import Review from "./Review/Review";

export default props => (
    <div class={`content-with-image cta-full-width ${props.ctaClass}`}>
        <div class="media">
            <img src={props.imgSrc} alt={props.altText} className={"fit-image"}/>
        </div>
        <div class="content" style={{ backgroundColor: props.ctaBGColor, color: props.bodyColor }}>
            {
                props.slideReviewID && ( <Review slideReviewID={props.slideReviewID} initialSlide={props.initialSlide} />)
            }
            <H3 headingText1={props.headingText1} headingText2={props.headingText2} headingColor={props.headingColor} />
            <p class="cta-blurb">{props.blurb}</p>
            <div class="cta-body">
                {props.children}
            </div>
            {
                props.footnoteText && (<p class="note" style={{ color: props.footnoteColor }}>{props.footnoteText}</p>)
            }
        </div>
    </div>
)