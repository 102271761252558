import React from "react"
import PropTypes, { element } from "prop-types"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import Hero from "../components/hero"
import Content from "../components/content-with-image"
import { Destini } from "@tyson-foods/react-component-library"
import Ct from "../components/content"

export default class AboutPage extends React.Component {
  componentDidMount() {
    if (window.location.href.includes("#news")) {
      var element = document.getElementById("news")
      switch (document.readyState) {
        case "interactive":
          element.scrollIntoView({
            behaviour: "smooth",
            block: "start",
            inline: "center",
          })
          break
      }
    }
  }
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>About Us</title>
          <meta
            name="description"
            content="Read about how Raised & Rooted™ uses pea protein to create delicious plant based proteins and meat alternatives. Learn about our brand and our products here."
          />
        </Helmet>
        <Layout>
          <Hero
            bgImage="/paper-background.jpg"
            eyebrowText=""
            eyebrowColor="#363636"
            headingText1="About Us"
            headingColor1="#006B2D"
            subheadingText1="Bringing the power"
            subheadingColor1="#006B2D"
            subheadingText2="of plant protein to everyone"
            subheadingColor2="#006B2D"
            bodyColor="#363636"
            bodyText="Whether you’re a flexitarian, vegetarian or carnivore - or just want to be healthier - we believe the food you eat should taste good and be good for you."
            buttonText="View Products"
            buttonLink="/products/"
            buttonColor="#FFFFFF"
            buttonBGColor="#006B2D"
          >
            <img src="/RaisedRooted-AboutUsPage-HeaderImage-SmashBurger-825x1112.png" alt="" />
          </Hero>
          <Content
            ctaClass="reverse"
            ctaBGColor="#97D700"
            headingColor="#006B2D"
            bodyColor="#006B2D"
            imgSrc="/RaisedRooted-AboutUsPage-LifeStyleImage-ChicNuggetParm-708x721.png"
            headingText2="A MEATIER TAKE ON PLANT PROTEIN"
            blurb="Raised & Rooted celebrates the delicious taste of meat without ever using it. It’s a revolutionary way to bring the power of plant protein into everyday eating. So between our Plant Based Nuggets, Burgers, Ground or Brats—you’ll find lots of ways to raise the taste expectation of plant-based foods. "
          >
            <h4>LITTLE NUGGETS, BIG PROTEIN</h4>
            <p>
              How can a simple plant based nugget pack in so much protein?
              Simple. You make it with a perfect blend of pea protein isolate
              and golden flaxseed. And let us tell you, it delivers on taste,
              too.
            </p>
            <h4>A BURGER MADE FROM PLANT POWER</h4>
            <p>
              We’re raising the bar on what a plant based burger can taste like.
              Not to mention, when you find out our plant based patty is made
              with 21 grams of protein per serving and 75% less saturated fat,
              you’ll be sure to savor in your decision.
            </p>
            <br></br>
            <p>
              But this isn’t all. Check out our full product lineup to
              experience all of our delicious plant based alternatives without
              sacrificing the meaty flavor you crave.
            </p>
          </Content>

          <Ct
            idName="news"
            ctaBottomMargin="30px"
            bgImage="/illustrated-background_news.svg"
            headingText2="News Nuggets"
            headingColor2="#006B2D"
            bodyColor="#006B2D"
            bodyText="We're pretty game-changing, but don't just take our word for it."
            buttonColor="#FFFFFF"
          >
            <p className="news-left">
              "These nuggets are still very new to the market and are produced
              under the Tyson brand umbrella. The "chicken" is made using pea
              protein, but what we like most about these is the breading. If
              you're a sucker for a McDonald's chicken nugget, this battered
              option is going to be your slam dunk."
            </p>
            <a
              className="news-left"
              href="https://www.foodnetwork.com/healthyeats/news/2019/10/plant-based-chicken-nuggets-trend"
              target="_blank"
              rel="noopener noreferrer"
            >
              Food Network — Are Plant Based Chicken Nuggets the New Meatless
              Burger?
            </a>
            <p className="news-left">
              "Everyone is getting into the alternative meat game, but not
              always with delicious results. I’ve had some questionably flavored
              faux chicken nuggets in the past, so I approached these frozen
              guys with skepticism. Turns out, they’re really delicious! They
              taste savory and salty and fast-food-y, like good nuggs should,
              and the coating bakes up nice and crisp."
            </p>
            <a
              className="news-left"
              href="https://www.thekitchn.com/new-grocery-reviews-october-2019-22950223?utm_source=twitter&utm_medium=social&utm_campaign=managed"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Kitchn — The Best New Groceries to Hit Supermarket Shelves
              This October
            </a>
            <p className="news-left">
              "This frozen brand made with pea protein wowed our kid tasters
              with the requisite crispy coating and dunkable shape. They have
              about one-third less saturated fat than the average white-meat
              chicken nuggets."
            </p>
            <a
              className="news-left"
              href="https://www.parents.com/parenting/better-parenting/green/parents-best-green-product-picks-of-2020/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Parents — Parents' Best Green Product Picks of 2020
            </a>
            <p className="news-right">
              "A great alternative to our kid favorite chicken nuggets, but a
              lot healthier so moms and dads can feel good about it!"
            </p>
            <a
              className="news-right"
              href="https://www.fox6now.com/fox6-wakeup-news/5-simple-ways-you-can-keep-your-family-happy-and-healthy?utm_source=capital_m_media"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fox6 / Yahoo! — 5 simple ways you can keep your family happy and
              healthy
            </a>
            <p className="news-right">
              "Plant Based Raised & Rooted Whole Grain Tenders are made with pea
              protein fiber and whole grain breading, and have 8g protein and 6g
              fiber per serving. Best of all, they actually do taste like
              chicken! Find them in the frozen aisle."
            </p>
            <a
              className="news-right"
              href="https://drive.google.com/file/d/1nmhPeOmXmlzlJuWr9-M_DFto97juU6yV/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Relish — First Bite "On Shelves"
            </a>
            <p className="news-right">
              "In October, Tyson partnered with Jack in the Box to launch The
              Unchicken Sandwich, the fast-food chain’s first meatless sandwich
              made with a Raised & Rooted vegan chicken patty."
            </p>
            <a
              className="news-right"
              href="https://vegnews.com/2020/12/tyson-drops-eggs-from-its-plant-based-nuggets-discontinues-half-vegan-blended-burger"
              target="_blank"
              rel="noopener noreferrer"
            >
              Veg News — Tyson Drops Eggs from Its Plant Based Nuggets;
              Discontinues Half Vegan Blended Burger
            </a>
          </Ct>
        </Layout>
      </React.Fragment>
    )
  }
}
